import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Checkbox, Table } from "antd";
import { EditOutlined } from "@ant-design/icons";

import {
  ORDER_LINE_STATUS_LABELS,
  ORDER_LINE_STATUS_COLORS,
  ORDER_LINE_STATUS_ICONS,
} from "constants/index";
import colors from "theme/colors";
import { formatPrice, stringifyModifiers } from "utils/helpers";

import { getProductColumns } from "../constants";

import BasketForm from "./BasketForm";
import NotesPopover from "./NotesPopover";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .ant-table-tbody > tr > td {
    padding: 10px 16px;
  }

  .ant-table-placeholder > .ant-table-cell {
    border-bottom: none;
  }

  .basket-title {
    background: ${colors.solitude};
    padding: 16px;
    font-size: 20px;
    text-align: center;
  }

  .prices-container {
    font-size: 20px;
    padding: 8px 16px;
  }

  .flex-price {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .delivery-price {
    font-size: 17px;
  }

  .min-delivery-price {
    font-size: 13px;
  }

  .table-container {
    flex: 1;
    background: ${colors.white};
  }
`;

const StyledTable = styled(Table)`
  td.ant-table-cell.ant-table-row-expand-icon-cell,
  th.ant-table-cell.ant-table-row-expand-icon-cell {
    display: none;
  }

  .ant-table-tbody {
    & > tr.ant-table-row:hover > td,
    & > tr.ant-table-expanded-row:hover > td {
      background: none;
    }

    & > tr:nth-child(odd) > td {
      border-bottom: none;
    }

    tr.ant-table-expanded-row > td {
      background: none;
      padding-top: 0;
    }
  }

  .ant-table-footer {
    padding: 0;
  }

  && tr.tr-disabled {
    background: ${colors.whisper};
  }
`;

const CreatedOrderLineContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ToPay = styled.div`
  font-weight: bold;
  font-size: 19px;
`;

const StatusText = styled.small`
  align-self: center;
  color: ${(props) => props.color};
`;

const EditModifierButton = styled.div`
  cursor: pointer;
`;

const EditModifiersBlock = ({ modifiers, onClick, t }) => (
  <EditModifierButton onClick={onClick}>
    {modifiers.length > 0 ? (
      modifiers.filter((m) => m.value.length > 0).map((modifier, index) => (
        <div key={modifier.modifier_group_id} style={{ marginLeft: index === 0 ? 15 : 29 }}>
          {index === 0 && <EditOutlined />}
          &nbsp;
          {modifier.list_group_name}: {stringifyModifiers(modifier.value)}
        </div>
      ))
    ) : (
      <div style={{ marginLeft: 15 }}>
        <EditOutlined />
        &nbsp;
        <i>{t("cart.BasketPanel.AddModifiers")}</i>
      </div>
    )}
  </EditModifierButton>
);

EditModifiersBlock.propTypes = {
  modifiers: PropTypes.array,
  onClick: PropTypes.func,
  t: PropTypes.func,
};

const BasketPanel = ({
  orderLines,
  paymentMethods,
  t,
  onDecrementOrDeleteOrderLine,
  onIncrementOrderLine,
  onDeleteOrderLine,
  onSaveOrderLineNotes,
  onOrderNotes,
  onSubmit,
  deliveryPrice,
  totalPrice,
  orderTypeInfo,
  orderLinesTotalPrice,
  orderNotes,
  onToggleOrderLineCancel,
  isEditingOrder,
  onEditModifiers,
}) => (
  <Container>
    <span className="basket-title">
      {t("cart.BasketPanel.Title")} ({(orderLines.reduce((acc, ol) => acc + ol.count, 0))})
    </span>

    <div className="table-container">
      <StyledTable
        rowKey={(ol) => ol.id
          || `open-${ol.menu_item.id}-${ol.notes}-${JSON.stringify(ol.modifiers)}`}
        dataSource={orderLines}
        columns={getProductColumns(
          t,
          onDecrementOrDeleteOrderLine,
          onIncrementOrderLine,
          onDeleteOrderLine,
        )}
        rowClassName={(ol) => (ol.id ? "tr-disabled" : "")}
        footer={() => (
          <NotesPopover
            style={{ padding: "16px 31px 15px", margin: 0 }}
            notes={orderNotes}
            onSave={onOrderNotes}
            addNotesText={t("cart.BasketPanel.AddOrderNotes")}
            placeholder={t("cart.BasketPanel.OrderNotesPlaceholder")}
            t={t}
          />
        )}
        expandable={{
          defaultExpandAllRows: true,
          expandedRowKeys: orderLines.map((ol) => ol.id
            || `open-${ol.menu_item.id}-${ol.notes}-${JSON.stringify(ol.modifiers)}`),
          expandedRowClassName: (ol) => (ol.id ? "tr-disabled" : ""),
          expandedRowRender: (orderLine, index) => (orderLine.id ? (
            <>
              <div style={{ position: "relative", top: -10 }}>
                {orderLine.modifiers?.filter((m) => m.value.length > 0)
                  .map((modifier) => (
                    <div key={modifier.modifier_group_id}>
                      &nbsp;&nbsp;&nbsp;
                      {modifier.list_group_name}: {stringifyModifiers(modifier.value)}
                    </div>
                  ))}
                {orderLine.notes && (
                  <div>
                    <i>&nbsp;&nbsp;&nbsp;({orderLine.notes})</i>
                  </div>
                )}
              </div>
              <CreatedOrderLineContainer>
                <StatusText color={ORDER_LINE_STATUS_COLORS[orderLine.status]}>
                  {ORDER_LINE_STATUS_ICONS[orderLine.status]}
                  &nbsp;
                  {ORDER_LINE_STATUS_LABELS[orderLine.status]}
                </StatusText>
                {(orderLine.status !== "cancelled" || orderLine.oldStatus) && (
                  <Checkbox
                    onChange={() => onToggleOrderLineCancel(index)}
                    checked={orderLine.status === "cancelled"}
                    style={{ color: colors.red }}
                  >
                    {t("cart.BasketPanel.Cancel")}
                  </Checkbox>
                )}
              </CreatedOrderLineContainer>
            </>
          ) : (
            <>
              {orderLine.modifiers && (
                <EditModifiersBlock
                  modifiers={orderLine.modifiers}
                  onClick={() => onEditModifiers({
                    orderLineIndex: index,
                    orderLine,
                    menuItem: orderLine.menu_item,
                  })}
                  t={t}
                />
              )}
              <NotesPopover
                notes={orderLine.notes}
                onSave={(notes) => onSaveOrderLineNotes(index, notes)}
                addNotesText={t("cart.BasketPanel.AddNotes")}
                placeholder={t("cart.BasketPanel.OrderLineNotesPlaceholder")}
                t={t}
              />
            </>
          )),
        }}
        pagination={false}
      />
    </div>

    <div className="prices-container">
      {orderTypeInfo && orderTypeInfo.type === "delivery" && (
        <div className="flex-price min-delivery-price">
          <span>{t("cart.BasketPanel.MinOrderSumForDelivery")}</span>
          <span>
            {formatPrice(orderTypeInfo.deliveryInfo.delivery_zone.min_order_sum_for_delivery)}
          </span>
        </div>
      )}
      {orderTypeInfo && orderTypeInfo.type === "delivery"
        && orderTypeInfo.deliveryInfo.delivery_zone.order_sum_for_free_delivery && (
        <div className="flex-price min-delivery-price">
          <span>{t("cart.BasketPanel.OrderSumForFreeDelivery")}</span>
          <span>
            {formatPrice(orderTypeInfo.deliveryInfo.delivery_zone.order_sum_for_free_delivery)}
          </span>
        </div>
      )}
      <div className="flex-price delivery-price">
        <span>{t("cart.BasketPanel.OrderTotal")}</span>
        <span>
          {formatPrice(orderLinesTotalPrice)}
        </span>
      </div>
      {orderTypeInfo && orderTypeInfo.deliveryInfo && (
        <div className="flex-price delivery-price">
          <span>{t("cart.BasketPanel.DeliveryPrice")}</span>
          <span>
            {formatPrice(deliveryPrice)}
          </span>
        </div>
      )}
      <ToPay className="flex-price">
        <span>{t("cart.BasketPanel.ToPay")}</span>
        <span>
          {formatPrice(totalPrice)}
        </span>
      </ToPay>
    </div>

    <BasketForm
      t={t}
      onSubmit={onSubmit}
      paymentMethods={paymentMethods}
      showPaymentMethodInput={
        !isEditingOrder && (orderTypeInfo ? orderTypeInfo.type === "delivery" : false)
      }
    />
  </Container>
);

BasketPanel.propTypes = {
  t: PropTypes.func.isRequired,
  orderLines: PropTypes.array.isRequired,
  orderLinesTotalPrice: PropTypes.number.isRequired,
  paymentMethods: PropTypes.array.isRequired,
  onDecrementOrDeleteOrderLine: PropTypes.func.isRequired,
  onIncrementOrderLine: PropTypes.func.isRequired,
  onSaveOrderLineNotes: PropTypes.func.isRequired,
  onDeleteOrderLine: PropTypes.func.isRequired,
  onOrderNotes: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  totalPrice: PropTypes.number.isRequired,
  deliveryPrice: PropTypes.number.isRequired,
  orderTypeInfo: PropTypes.shape({
    type: PropTypes.string.isRequired,
    deliveryInfo: PropTypes.shape({
      delivery_price: PropTypes.number,
      delivery_zone: PropTypes.object,
    }),
  }),
  orderNotes: PropTypes.string,
  onToggleOrderLineCancel: PropTypes.func,
  isEditingOrder: PropTypes.bool,
  onEditModifiers: PropTypes.func,
};

export default BasketPanel;
