import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { formatPrice, formatRelativeTime, stringifyModifiers } from "../../../utils/helpers";
import colors from "../../../theme/colors";
import { STATUSES } from "../constants";

const Th = styled.th`
  font-size: 12px;
  padding: 20px 8px 8px 8px;

  :first-child {
    padding-left: 28px;
  }

  :last-child {
    padding-right: 28px;
  }
`;

const Td = styled.td`
  padding: 8px;
  line-height: 1.43;
  vertical-align: top;

  :first-child {
    padding-left: 28px;
  }

  :last-child {
    padding-right: 28px;
  }
`;

const Thead = styled(({ columns, className }) => (
  <thead className={className}>
    <tr>
      {columns.map((column) => (
        <Th key={column}>{column}</Th>
      ))}
    </tr>
  </thead>
))`
  border-bottom: 1px solid ${colors.whisper};
`;

const Table = styled.table`
  background-color: ${colors.white};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
`;

Thead.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  className: PropTypes.string,
};

const OrderLinesTable = ({ order, t }) => (
  <Table index>
    <Thead columns={[
      t("checkClient.OrderLinesTable.Item"),
      t("checkClient.OrderLinesTable.Notes"),
      t("checkClient.OrderLinesTable.Qty"),
      t("checkClient.OrderLinesTable.Total"),
      t("checkClient.OrderLinesTable.Status"),
    ]}
    />
    <tbody>
      {order.order_lines.map((order_line) => {
        const currentStatus = STATUSES[order_line.status];
        return (
          <tr key={order_line.id}>
            <Td>
              {order_line.display_name}
              {order_line.modifiers?.filter((m) => m.value.length > 0)
                .map((modifier) => (
                  <div key={modifier.modifier_group_id}>
                    &nbsp;&nbsp;&nbsp;
                    {modifier.list_group_name}: {stringifyModifiers(modifier.value)}
                  </div>
                ))}
            </Td>
            <Td>{order_line.notes}</Td>
            <Td>{order_line.count}</Td>
            <Td>{formatPrice(order_line.total_price)}</Td>
            <Td>{currentStatus.label} {formatRelativeTime(order_line.status_updated_at)}</Td>
          </tr>
        );
      })}
    </tbody>
  </Table>
);

OrderLinesTable.propTypes = {
  order: PropTypes.shape({
    order_lines: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        display_name: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired,
        total_price: PropTypes.number.isRequired,
        status: PropTypes.string.isRequired,
      }),
    ),
  }),
  t: PropTypes.func.isRequired,
};

export default OrderLinesTable;
