import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Form, Input, Button } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import colors from "../../../theme/colors";

const StyledForm = styled(Form)`
  width: 300px;

  .login-form-forgot {
    float: right;
    padding: 0px;
  }

  .ant-form-item {
    margin-bottom: 12px;
  }

  .site-form-item-icon {
    color: ${colors.grey};
  }
`;

const LoginForm = ({ onSubmit }) => {
  const { t } = useTranslation();
  return (
    <StyledForm name="login_form" onFinish={onSubmit}>
      <Form.Item
        name="email"
        rules={[{ required: true, type: "email" }]}
      >
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Email"
        />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[{ required: true, min: 8 }]}
      >
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          placeholder={t("auth.LoginForm.Password.Placeholder")}
        />
      </Form.Item>

      <Form.Item>
        <Button
          type="link"
          className="login-form-forgot"
        >
          {t("auth.LoginForm.ForgotPassword")}
        </Button>
        <Button block type="primary" htmlType="submit">
          {t("auth.LoginForm.Login")}
        </Button>
      </Form.Item>
    </StyledForm>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default LoginForm;
