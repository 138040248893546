/* eslint-disable no-nested-ternary */

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import { withRouter } from "react-router-dom";
import { Row, Col, Spin, notification, Button } from "antd";
import { useTranslation } from "react-i18next";

import { DELIVERY_ZONES_AND_LOCATIONS_QUERY } from "./newOrderQueries";
import { CUSTOMER_QUERY } from "../checkClient/checkClientQueries";
import { COUNTRY_CODE_NUMBER } from "../../constants";
import colors from "../../theme/colors";
import OrderData from "../../OrderData.container";

import Footer from "../../components/Footer";
import ClientForm from "./components/ClientForm";
import LocationInfo from "./components/LocationInfo";

const Container = styled.div`
  padding: 50px;

  .error-text {
    font-size: 16px;
    color: ${colors.red};
  }
`;

const Loader = styled(Spin)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

const FooterButton = styled(Button)`
  width: 100%;
  height: 100%;
  border-radius: 0px;
  font-size: 20px;
`;

const NewOrder = ({ history }) => {
  const { t } = useTranslation();
  const {
    customer,
    setCustomer,
    orderTypeInfo,
    setDeliveryInfo,
    setPickupLocation,
    resetOrderTypeInfo,
  } = OrderData.useContainer();

  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedPickupLocation, setSelectedPickupLocation] = useState(null);

  useEffect(() => {
    if (orderTypeInfo?.deliveryInfo?.address) {
      setSelectedAddress(orderTypeInfo.deliveryInfo.address);
    }
  }, []);

  const { data, loading } = useQuery(DELIVERY_ZONES_AND_LOCATIONS_QUERY, {
    onError: notification.error,
  });
  const customerQuery = useQuery(CUSTOMER_QUERY, {
    onError: notification.error,
    variables: { phone_number: customer.phone_number },
  });

  if (loading || customerQuery.loading) return <Loader />;

  const foundCustomer = customerQuery.data.customers[0];
  const foundDeliveryZone = selectedAddress && data.delivery_zones.find((zone) => (
    booleanPointInPolygon(selectedAddress.geo.coordinates, zone.geo)
  ));

  const handleSubmit = (values) => {
    setCustomer({
      name: values.name,
      phone_number: COUNTRY_CODE_NUMBER.concat(values.phone_number),
    });
    if (selectedPickupLocation) {
      const pickupLocation = data.locations.find((location) => (
        location.id === selectedPickupLocation
      ));
      setPickupLocation(pickupLocation);
    } else if (selectedAddress) {
      const address = values.address_id
        ? selectedAddress
        : { ...selectedAddress, street: values.street, notes: values.notes, flat: values.flat };
      setDeliveryInfo(address, foundDeliveryZone);
    } else {
      resetOrderTypeInfo();
    }
    history.push("/cart");
  };

  const getAddressFormValues = ({ deliveryInfo, locationId }) => {
    if (deliveryInfo) {
      if (deliveryInfo.address_id) {
        return { address_id: deliveryInfo.address_id };
      }
      return deliveryInfo.address;
    }
    return { location_id: locationId };
  };

  const initialValuesCustomer = orderTypeInfo ? {
    ...foundCustomer,
    ...customer,
    address_source: orderTypeInfo.deliveryInfo
      ? (orderTypeInfo.deliveryInfo.address_id ? "past_addresses" : "new_address")
      : "pickup",
    ...getAddressFormValues(orderTypeInfo),
    phone_number: customer.phone_number.slice(2),
  } : {
    ...foundCustomer,
    ...customer,
    address_source: (foundCustomer && foundCustomer.customer_addresses.length > 0)
      ? "past_addresses" : "new_address",
    phone_number: customer.phone_number.slice(2),
  };

  return (
    <div>
      <Container>
        <Row>
          <Col span={12}>
            <ClientForm
              t={t}
              onSubmit={handleSubmit}
              initialValues={initialValuesCustomer}
              pastAddresses={foundCustomer?.customer_addresses ?? []}
              locations={data?.locations ?? []}
              onSelectAddress={setSelectedAddress}
              onSelectPickupLocation={setSelectedPickupLocation}
            />
          </Col>
          <Col span={12}>
            {selectedAddress ? (
              <LocationInfo t={t} address={selectedAddress} deliveryZone={foundDeliveryZone} />
            ) : (
              <span className="error-text">
                {t("newOrder.NoLocationMessage")}
              </span>
            )}
          </Col>
        </Row>
      </Container>
      <Footer>
        <FooterButton
          form="client-form"
          key="submit"
          htmlType="submit"
          type="primary"
          disabled={selectedAddress && !foundDeliveryZone}
        >
          {(selectedPickupLocation || selectedAddress)
            ? t("newOrder.SelectAddress") : t("newOrder.ToMenu")}
        </FooterButton>
      </Footer>
    </div>
  );
};

NewOrder.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(NewOrder);
