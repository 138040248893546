import React, { useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useLazyQuery, useQuery } from "@apollo/client";
import { withRouter } from "react-router-dom";
import {
  Row, Col, notification, Spin, Button, Modal, message,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import { CART_DATA, GET_ORDER_QUERY } from "./cartQueries";
import { formatPrice } from "../../utils/helpers";
import colors from "../../theme/colors";
import OrderData from "../../OrderData.container";

import BasketPanel from "./components/BasketPanel";
import ItemsPanel from "./components/ItemsPanel";
import Footer from "../../components/Footer";
import ModifiersModal from "./components/ModifiersModal";

const Container = styled.div`
  position: absolute;
  top: 64px;
  bottom: 0;
  left: 0;
  right: 0;

  .cart-row-container {
    height: 100%;
    padding-bottom: 50px;
  }

  .overflow-col {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .basket-container {
    border-left: 1px solid ${colors.silver}
  }
`;

const Loader = styled(Spin)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

const FooterButton = styled(Button)`
  width: 100%;
  height: 100%;
  border-radius: 0px;
  font-size: 20px;
  &[disabled] {
    color: #fff;
    background-color: ${colors.red};
    border-color: ${colors.red};
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  }
`;

const SubmitButton = ({ t, isEditingOrder, isProcessingOrder, orderLines, totalPrice }) => (
  <FooterButton
    form="basket-form"
    key="submit"
    htmlType="submit"
    type="primary"
    disabled={isProcessingOrder || orderLines.length === 0}
  >
    {orderLines.length > 0
      ? `${t(isEditingOrder ? "cart.EditOrder" : "cart.CreateOrder")} - ${formatPrice(totalPrice)}`
      : t("cart.EmptyCart")}
  </FooterButton>
);

SubmitButton.propTypes = {
  t: PropTypes.func,
  isEditingOrder: PropTypes.bool,
  isProcessingOrder: PropTypes.bool,
  orderLines: PropTypes.array.isRequired,
  totalPrice: PropTypes.number,
};

const Cart = ({ history }) => {
  const { t } = useTranslation();
  const {
    orderTypeInfo,
    orderLines,
    orderLinesTotalPrice,
    deliveryPrice,
    totalPrice,
    orderNotes,
    targetOrder,
    createOrder,
    changeOrder,
    filterOrderLinesByMenuItemIds,
    addMenuItemToCart,
    incrementOrderLine,
    decrementOrDeleteOrderLine,
    editOrderLineNotes,
    editOrderLineModifiers,
    deleteOrderLine,
    initializeCart,
    setNotes,
    toggleOrderLineCancel,
  } = OrderData.useContainer();
  const [isProcessingOrder, setProcessingOrder] = useState(false);
  const { data, loading } = useQuery(CART_DATA, {
    onError: notification.error,
  });

  const [getOrderToEdit, { loading: isFetchingOrder }] = useLazyQuery(GET_ORDER_QUERY, {
    fetchPolicy: "cache-and-network",
    onCompleted: (res) => res.orders_by_pk && initializeCart(res.orders_by_pk),
  });

  useEffect(() => {
    if (targetOrder?.id) {
      getOrderToEdit({ variables: { id: targetOrder.id } });
    }
  }, [targetOrder?.id]);

  const [modifierModal, setModifierModal] = useState();

  const menuCategories = useMemo(() => {
    if (data) {
      const locationId = orderTypeInfo?.locationId;
      return data.menu_categories.map((category) => {
        const menuItemGroups = category.menu_item_groups.map((mig) => {
          const menuItems = mig.menu_items.filter(({ limited_location_availability: lla }) => (
            lla === null || !locationId || lla.includes(locationId)
          ));
          return { ...mig, menu_items: menuItems };
        })
          .filter((mig) => mig.menu_items.length > 0);
        return { ...category, menu_item_groups: menuItemGroups };
      })
        .filter((category) => category.menu_item_groups.length > 0);
    }
    return [];
  }, [data?.menu_categories, orderTypeInfo?.locationId]);

  useEffect(() => {
    if (menuCategories.length > 0) {
      filterOrderLinesByMenuItemIds(
        menuCategories.flatMap((cat) => cat.menu_item_groups)
          .flatMap((mig) => mig.menu_items)
          .map((mi) => mi.id),
      );
    }
  }, [menuCategories]);

  if (loading || isFetchingOrder) return <Loader />;

  const handleSubmit = (values) => {
    if (orderTypeInfo?.type === "delivery"
      && orderTypeInfo.deliveryInfo.delivery_zone.min_order_sum_for_delivery
        > orderLinesTotalPrice) {
      const notEnoughAmount = orderTypeInfo.deliveryInfo.delivery_zone.min_order_sum_for_delivery
        - orderLinesTotalPrice;
      Modal.confirm({
        title: t(
          targetOrder
            ? "cart.WarningChangeOrderModal.notEnoughAmountMessage"
            : "cart.WarningCreateOrderModal.notEnoughAmountMessage",
          { notEnoughAmount: formatPrice(notEnoughAmount) },
        ),
        onOk() {},
      });
      return;
    }

    if (orderLines.length > 0 && orderTypeInfo) {
      setProcessingOrder(true);
      (targetOrder ? changeOrder(values) : createOrder(values))
        .then(() => history.push("/check-client"))
        .then(() => message.success(t(
          targetOrder
            ? "cart.ChangeOrderModal.SuccessMessage"
            : "cart.CreateOrderModal.SuccessMessage",
        )))
        .catch(notification.error)
        .finally(() => setProcessingOrder(false));
    } else if (orderLines.length > 0) {
      Modal.confirm({
        title: t("cart.CreateOrderModal.Title"),
        icon: <ExclamationCircleOutlined />,
        okText: t("cart.CreateOrderModal.OkText"),
        cancelText: t("cart.CreateOrderModal.Cancel"),
        onOk() {
          history.push("/new-order");
        },
      });
    }
  };

  const handleMenuItem = (menuItem) => {
    if (menuItem.menu_item_modifier_groups.length > 0) {
      setModifierModal({ menuItem });
    } else {
      addMenuItemToCart(menuItem);
    }
  };

  const handleModifierModal = (menuItem) => {
    const { orderLineIndex, modifiers, price } = menuItem.orderLineData;
    if (typeof orderLineIndex === "number") {
      editOrderLineModifiers(orderLineIndex, modifiers, price);
    } else {
      addMenuItemToCart(menuItem);
    }
  };

  return (
    <Container>
      <Row className="cart-row-container">
        <Col span={18} className="overflow-col">
          <ItemsPanel
            categories={menuCategories}
            orderLines={orderLines}
            onAddMenuItemToCard={handleMenuItem}
          />
        </Col>
        <Col span={6} className="overflow-col basket-container">
          <BasketPanel
            t={t}
            paymentMethods={data.payment_methods}
            orderLines={orderLines}
            orderLinesTotalPrice={orderLinesTotalPrice}
            onDecrementOrDeleteOrderLine={decrementOrDeleteOrderLine}
            onIncrementOrderLine={incrementOrderLine}
            onDeleteOrderLine={deleteOrderLine}
            onSaveOrderLineNotes={editOrderLineNotes}
            onOrderNotes={setNotes}
            onSubmit={handleSubmit}
            deliveryPrice={deliveryPrice}
            totalPrice={totalPrice}
            orderTypeInfo={orderTypeInfo}
            orderNotes={orderNotes}
            isEditingOrder={!!targetOrder}
            onToggleOrderLineCancel={toggleOrderLineCancel}
            onEditModifiers={setModifierModal}
          />
        </Col>
      </Row>
      <Footer>
        <SubmitButton
          t={t}
          isEditingOrder={!!targetOrder}
          isProcessingOrder={isProcessingOrder}
          orderLines={orderLines}
          totalPrice={totalPrice}
        />
      </Footer>
      {modifierModal && (
        <ModifiersModal
          t={t}
          menuItem={modifierModal.menuItem}
          orderLineIndex={modifierModal.orderLineIndex}
          orderLine={modifierModal.orderLine}
          onClose={() => setModifierModal(null)}
          onSubmit={handleModifierModal}
        />
      )}
    </Container>
  );
};

Cart.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Cart);
