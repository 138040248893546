import gql from "graphql-tag";

export const CART_DATA = gql`
  query @hasura {
    menu_categories(
      order_by: {position: asc_nulls_last}
      where: {menu_item_groups: {id: {_is_null: false}}}
    ) {
      id
      name
      menu_item_groups(
        order_by: {name: asc}
      ) {
        id
        name
        category_id
        description
        menu_items(order_by: {price: asc}) {
          id
          name
          price
          limited_location_availability
          menu_item_group {
            id
            name
          }
          menu_item_modifier_groups(order_by: {id: asc}) {
            modifier_group {
              id
              description
              created_at
              max_selection
              merchant_id
              min_selection
              name
              updated_at
              modifier_items(order_by: {id: asc}) {
                id
                default_value
                created_at
                modifier_group_id
                name
                price
                updated_at
              }
            }
          }
        }
      }
    }
    payment_methods(where: {show_in_callcenter: {_eq: true }}, order_by: {id: asc}) {
      id
      name
      type
    }
  }
`;

export const GET_ORDER_QUERY = gql`
  query GetOrderQuery($id: bigint!) @hasura {
    orders_by_pk(id: $id) {
      id
      status
      number
      notes
      type
      source
      location {
        id
        name
      }
      order_lines {
        id
        status
        count
        notes
        price
        modifiers
        menu_item {
          id
          name
          price
          limited_location_availability
          menu_item_group {
            id
            name
          }
        }
      }
      order_price_updates(order_by: {id: desc}, limit: 1) {
        id
        discount
        service_percent
        delivery_price
      }
      delivery_info {
        id
        address {
          street
          house
          flat
          notes
        }
        delivery_zone {
          id
          updated_at
          price
          order_sum_for_free_delivery
          name
          min_order_sum_for_delivery
          location_id
          geo
          description
          created_at
          location {
            id
          }
        }
      }
    }
  }
`;

export const CREATE_ORDER_MUTATION = gql`
  mutation CreateOrder($order: OrderInput!, $location_id: Int!) {
    createOrder(order: $order, location_id: $location_id) {
      id
    }
  }
`;

export const CHANGE_ORDER_MUTATION = gql`
  mutation ChangeOrder($order_id: ID!, $changes: OrderChangesInput!) {
    changeOrder(order_id: $order_id, changes: $changes) {
      id
    }
  }
`;

export const CANCEL_ORDER_MUTATION = gql`
  mutation CancelOrder($order_id: ID!, $reason: String!) {
    cancelOrder(order_id: $order_id, reason: $reason) {
      id
      status
    }
  }
`;
