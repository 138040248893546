import { useState, useMemo } from "react";
import { createContainer } from "unstated-next";
import moment from "moment";
import { useMutation } from "@apollo/client";

import { getMenuItemName, isModifiersEqual } from "utils/helpers";
import { CHANGE_ORDER_MUTATION, CREATE_ORDER_MUTATION, CANCEL_ORDER_MUTATION } from "./pages/cart/cartQueries";

const useOrderData = () => {
  const [createOrderMutation] = useMutation(CREATE_ORDER_MUTATION);
  const [changeOrderMutation] = useMutation(CHANGE_ORDER_MUTATION);
  const [cancelOrderMutation] = useMutation(CANCEL_ORDER_MUTATION);

  const [orderTypeInfo, setOrderTypeInfo] = useState();
  const [customer, setCustomer] = useState();
  const [discount, setDiscount] = useState(0);
  const [orderLines, setOrderLines] = useState([]);
  const [paymentMethodId, setPaymentMethodId] = useState();
  const [cash, setCash] = useState();
  const [orderNotes, setNotes] = useState();
  const [orderLinesToCancel, setOrderLinesToCancel] = useState([]);
  const [targetOrder, setTargetOrder] = useState();

  const orderLinesTotalPrice = useMemo(() => orderLines
    .filter((ol) => ol.status !== "cancelled")
    .reduce((acc, ol) => (acc + ol.count * ol.price), 0), [orderLines]);

  const deliveryPrice = useMemo(() => {
    if (orderTypeInfo && orderTypeInfo.type === "delivery") {
      const {
        price, order_sum_for_free_delivery: freeLimit,
      } = orderTypeInfo.deliveryInfo.delivery_zone;
      return freeLimit && orderLinesTotalPrice >= freeLimit ? 0 : price;
    }
    return 0;
  }, [orderLinesTotalPrice, orderTypeInfo]);

  const orderLinesTotalPriceWithDiscount = useMemo(() => (
    orderLinesTotalPrice - (orderLinesTotalPrice * discount)
  ), [orderLinesTotalPrice, discount]);

  const totalPrice = useMemo(() => (
    orderLinesTotalPriceWithDiscount + deliveryPrice
  ), [orderLinesTotalPriceWithDiscount, deliveryPrice]);

  const setDeliveryInfo = (address, deliveryZone) => {
    const addressFields = address.id ? { address_id: address.id } : { address };
    setOrderTypeInfo({
      type: "delivery",
      locationId: deliveryZone.location.id,
      locationInfo: [address.city, address.street, address.flat].filter((v) => v).join(", "),
      deliveryInfo: {
        delivery_zone: deliveryZone,
        ...addressFields,
      },
    });
  };

  const setPickupLocation = (location) => setOrderTypeInfo({
    type: "pickup",
    locationId: location.id,
    locationInfo: `Самовывоз: ${location.name}`,
  });

  const resetOrderTypeInfo = () => setOrderTypeInfo(null);

  const addMenuItemToCart = ({ orderLineData = {}, ...menuItem }) => {
    const foundOrderLineIdx = orderLines.findIndex((ol) =>
      ol.menu_item.id === menuItem.id && !ol.notes && !ol.id
        && isModifiersEqual(orderLineData.modifiers, ol.modifiers));

    if (foundOrderLineIdx >= 0) {
      setOrderLines(orderLines.map((orderLine, idx) => {
        if (idx === foundOrderLineIdx) {
          return { ...orderLine, count: orderLine.count + 1 };
        }
        return orderLine;
      }));
    } else {
      setOrderLines(orderLines.concat({
        menu_item: menuItem, count: 1, price: menuItem.price, ...orderLineData,
      }));
    }
  };

  const incrementOrderLine = (idx) => {
    setOrderLines(orderLines.map((orderLine, i) => {
      if (i === idx) {
        return { ...orderLine, count: orderLine.count + 1 };
      }
      return orderLine;
    }));
  };

  const decrementOrDeleteOrderLine = (idx) => {
    if (orderLines[idx].count - 1 > 0) {
      setOrderLines(orderLines.map((orderLine, i) => {
        if (i === idx) {
          return { ...orderLine, count: orderLine.count - 1 };
        }
        return orderLine;
      }));
    } else {
      setOrderLines(orderLines.filter((ol, i) => i !== idx));
    }
  };

  const editOrderLineNotes = (idx, notes) => {
    const trimmedNotes = notes.trim() || null;
    setOrderLines(orderLines.map((orderLine, i) => {
      if (i === idx) {
        return { ...orderLine, notes: trimmedNotes };
      }
      return orderLine;
    }));
  };

  const editOrderLineModifiers = (idx, modifiers, price) => {
    setOrderLines(orderLines.map((orderLine, i) => {
      if (i === idx) {
        return { ...orderLine, modifiers, price };
      }
      return orderLine;
    }));
  };

  const deleteOrderLine = (idx) => {
    setOrderLines(orderLines.filter((ol, i) => i !== idx));
  };

  const filterOrderLinesByMenuItemIds = (menuItemsIds) => {
    if (orderLines.length > 0) {
      setOrderLines(orderLines.filter((ol) => menuItemsIds.includes(ol.menu_item.id)));
    }
  };

  const createOrder = ({ payment_method_id, cash: formCash }) => {
    const order_lines = orderLines.map(({ menu_item, count, notes, modifiers, price }) => ({
      display_name: getMenuItemName(menu_item),
      price,
      total_price: price * count,
      count,
      menu_item_id: menu_item.id,
      notes,
      modifiers,
    }));

    const orderData = {
      type: orderTypeInfo.type,
      delivery_price: deliveryPrice,
      list_price: totalPrice,
      order_lines_total: orderLinesTotalPrice,
      service_percent: 0,
      discount,
      time: moment().format(),
    };

    if (orderTypeInfo.type === "delivery") {
      const { delivery_zone, ...deliveryInfo } = orderTypeInfo.deliveryInfo;

      const delivery_info = {
        ...deliveryInfo,
        delivery_zone_id: delivery_zone.id,
        payment_method_id,
        cash_return: formCash ? (formCash - totalPrice) : 0,
      };
      return createOrderMutation({
        variables: {
          order: {
            ...orderData,
            customer,
            delivery_info,
            order_lines,
            notes: orderNotes,
          },
          location_id: orderTypeInfo.locationId,
        },
      });
    }
    return createOrderMutation({
      variables: {
        order: {
          ...orderData,
          customer,
          order_lines,
          notes: orderNotes,
        },
        location_id: orderTypeInfo.locationId,
      },
    });
  };

  const changeOrder = (values) => {
    const newOrderLines = orderLines.filter((ol) => !ol.id)
      .map(({ menu_item, count, notes }) => ({
        display_name: getMenuItemName(menu_item),
        total_price: menu_item.price * count,
        price: menu_item.price,
        count,
        menu_item_id: menu_item.id,
        notes,
      }));
    const { discount: oldDiscount, service_percent } = targetOrder.order_price_updates[0];

    const messages = [targetOrder.status];
    if (Math.abs(values.discount - oldDiscount) > 0.001) messages.push("discount");
    if (newOrderLines.length > 0) messages.push("added order lines");
    if (orderLinesToCancel.length > 0) {
      orderLinesToCancel.forEach(({ menu_item }) => messages.push(`removed: ${getMenuItemName(menu_item)}`));
    }

    const orderPriceUpdate = messages.length > 1 ? {
      message: messages.join(", "),
      order_lines_total: orderLinesTotalPrice,
      delivery_price: deliveryPrice,
      discount,
      list_price: totalPrice,
      service_percent,
    } : undefined;

    const orderLineCancels = orderLinesToCancel.map((ol) => ({ order_line_id: ol.id.toString() }));

    return changeOrderMutation({
      variables: {
        order_id: targetOrder.id.toString(),
        changes: {
          newOrderLines,
          orderLineCancels,
          orderPriceUpdate,
          notes: orderNotes,
        },
      },
    });
  };

  const cancelOrder = (reason) => cancelOrderMutation({
    variables: { order_id: targetOrder.id, reason },
  });

  const toggleOrderLineCancel = (idx) => {
    const isCancelled = orderLinesToCancel.some((ol) => ol.id === orderLines[idx].id);
    if (isCancelled) {
      setOrderLines(orderLines.map((ol, index) => ({
        ...ol, ...(index === idx ? { status: ol.oldStatus } : {}),
      })));
      setOrderLinesToCancel(orderLinesToCancel.filter((ol) => ol.id !== orderLines[idx].id));
    } else {
      setOrderLines(orderLines.map((ol, index) => ({
        ...ol, ...(index === idx ? { status: "cancelled", oldStatus: ol.status } : {}),
      })));
      setOrderLinesToCancel([...orderLinesToCancel, orderLines[idx]]);
    }
  };

  const initializeCart = (order) => {
    setOrderLines(order.order_lines);
    if (order.type === "delivery") {
      setDeliveryInfo(order.delivery_info.address, order.delivery_info.delivery_zone);
    }
    if (order.type === "pickup") setPickupLocation(order.location);
    setNotes(order.notes);
    setTargetOrder(order);
    setDiscount(order.order_price_updates[0].discount);
  };

  const reset = () => {
    setOrderTypeInfo(null);
    setCustomer(null);
    setOrderLines([]);
    setDiscount(0);
    setPaymentMethodId(null);
    setCash(null);
    setNotes(null);
    setTargetOrder(null);
    setOrderLinesToCancel([]);
  };

  return ({
    orderTypeInfo,
    setDeliveryInfo,
    setPickupLocation,
    resetOrderTypeInfo,

    customer,
    setCustomer,

    orderLines,
    addMenuItemToCart,
    incrementOrderLine,
    decrementOrDeleteOrderLine,
    editOrderLineNotes,
    editOrderLineModifiers,
    deleteOrderLine,
    filterOrderLinesByMenuItemIds,

    discount,
    setDiscount,

    orderLinesTotalPrice,
    deliveryPrice,
    totalPrice,

    createOrder,
    changeOrder,
    cancelOrder,

    targetOrder,
    setTargetOrder,

    toggleOrderLineCancel,

    paymentMethodId,
    setPaymentMethodId,

    cash,
    setCash,

    orderNotes,
    setNotes,

    initializeCart,
    reset,
  });
};

export default createContainer(useOrderData);
