import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useLazyQuery } from "@apollo/client";
import {
  Row, Col, Table, Spin, notification,
} from "antd";
import { useTranslation } from "react-i18next";

import colors from "../../theme/colors";
import { getOrderColumns, getDisplayNumber } from "./constants";
import {
  CUSTOMER_QUERY,
  CUSTOMER_ORDERS_QUERY,
  CUSTOMER_COMPLETED_ORDERS_QUERY,
} from "./checkClientQueries";
import { ORDER_STATUS_LABELS } from "../../constants";
import OrderData from "../../OrderData.container";

import PhoneNumberForm from "./components/PhoneNumberForm";
import OrderLinesTable from "./components/OrderLinesTable";
import CancelOrderModal from "./components/CancelOrderModal";

const Container = styled.div`
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .ant-table-title {
    background: ${colors.solitude};
    font-size: 20px;
  }
`;

const StyledRow = styled(Row)`
  margin: 20px 0px;
`;

const Loader = styled(Spin)`
  margin-top: 72px;
`;

const WelcomeText = styled.span`
  font-size: 24px;
  font-family: sans-serif;
  ${(props) => props.name && "font-weight: 700;"}
`;

const CheckClient = ({ match, history }) => {
  const { t } = useTranslation();
  const { pathPhoneNumber } = match.params;
  const { setCustomer, reset, cancelOrder, targetOrder, setTargetOrder } = OrderData.useContainer();
  const [cancelModalVisible, setCancelModalVisible] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState();

  const [getCustomer, customer] = useLazyQuery(CUSTOMER_QUERY, {
    onError: notification.error,
    fetchPolicy: "cache-and-network",
  });
  const [getCustomerOrders, customerOrders] = useLazyQuery(CUSTOMER_ORDERS_QUERY, {
    onError: notification.error,
    fetchPolicy: "cache-and-network",
  });
  const [getCustomerCompletedOrders, customerCompletedOrders] = useLazyQuery(
    CUSTOMER_COMPLETED_ORDERS_QUERY, {
      onError: notification.error,
      fetchPolicy: "cache-and-network",
    },
  );

  const handleCancelOrder = (reason) => cancelOrder(reason)
    .then(() => {
      customerCompletedOrders.refetch();
      customerOrders.refetch();
    })
    .catch(notification.error);

  useEffect(() => {
    if (pathPhoneNumber) {
      getCustomer({ variables: { phone_number: pathPhoneNumber } });
      setPhoneNumber(pathPhoneNumber);
    }
  }, [pathPhoneNumber]);

  useEffect(() => {
    if (customer.data?.customers[0]) {
      getCustomerOrders({
        variables: {
          customer_id: customer.data.customers[0].id,
        },
      });
      getCustomerCompletedOrders({
        variables: {
          customer_id: customer.data.customers[0].id,
        },
      });
    }
  }, [customer]);

  const handleSubmit = (values) => {
    setPhoneNumber(values.phone_number);
    getCustomer({ variables: values });
  };

  const handleNewOrder = () => {
    reset();
    setCustomer(customer.data?.customers[0] ?? { phone_number: phoneNumber });
    history.push("/new-order");
  };

  const handleEditOrder = (order) => {
    reset();
    setCustomer(customer.data?.customers[0] ?? { phone_number: phoneNumber });
    setTargetOrder(order);
    history.push("/cart");
  };

  return (
    <Container>
      <StyledRow justify="center">
        <PhoneNumberForm
          t={t}
          onSubmit={handleSubmit}
          onNewOrder={handleNewOrder}
          isDisabledNewOrder={!customer.data}
          initialValues={{ phone_number: pathPhoneNumber?.slice(2) }}
        />
      </StyledRow>

      {customer.data && (customer.data.customers[0] ? (
        <StyledRow justify="center">
          <WelcomeText>{t("checkClient.ExistingCustomer")}&nbsp;</WelcomeText>
          <WelcomeText name="true">{customer.data.customers[0].name}</WelcomeText>
        </StyledRow>
      ) : (
        <StyledRow justify="center">
          <WelcomeText>{t("checkClient.NewCustomer")}</WelcomeText>
        </StyledRow>
      ))}

      {customer.loading ? (
        <Loader />
      ) : customer.data && customer.data.customers[0] && (
        <StyledRow>
          <Col span={24}>
            <Table
              rowKey="id"
              scroll={{ x: true }}
              dataSource={customerOrders.data && customerOrders.data.orders}
              columns={getOrderColumns(
                handleEditOrder,
                (order) => {
                  setTargetOrder(order);
                  setCancelModalVisible(true);
                },
              )}
              loading={customerOrders.loading}
              title={() => <span>{t("checkClient.CurrentOrders")}</span>}
              expandedRowRender={(order) => <OrderLinesTable t={t} order={order} />}
            />
          </Col>
          <Col span={24}>
            <Table
              rowKey="id"
              scroll={{ x: true }}
              dataSource={customerCompletedOrders.data
                && customerCompletedOrders.data.orders}
              columns={getOrderColumns()}
              loading={customerCompletedOrders.loading}
              title={() => (
                <span>
                  {t("checkClient.PastOrders", {
                    count: customerCompletedOrders.data
                      ? customerCompletedOrders.data.orders_aggregate.aggregate.count : 0,
                  })}
                </span>
              )}
            />
          </Col>
        </StyledRow>
      )}

      <CancelOrderModal
        t={t}
        hint={targetOrder && t("checkClient.CancelModal.OrderOnDeliveryHint",
          { status: ORDER_STATUS_LABELS[targetOrder.status] })}
        title={targetOrder && t("checkClient.CancelModal.Title",
          { order_number: getDisplayNumber(null, targetOrder) })}
        visible={cancelModalVisible}
        onClose={() => {
          setTargetOrder(null);
          setCancelModalVisible(false);
        }}
        onSubmit={handleCancelOrder}
      />
    </Container>
  );
};

CheckClient.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object,
};

export default CheckClient;
