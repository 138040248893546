import React, { useState, useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {
  Form, Row, Col, Input, Radio, Select,
} from "antd";

import { PHONE_NUMBER_REGEX, COUNTRY_CODE_NUMBER } from "../../../constants/index";
import { ADDRESS_SOURCE } from "../constants";
import colors from "../../../theme/colors";

const formItem = {
  labelCol: {
    span: 14,
  },
  wrapperCol: {
    span: 14,
  },
};

const formItemAddress = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
  style: {
    marginBottom: 6,
  },
};

const radioStyle = {
  display: "block",
  lineHeight: "30px",
};

const FormItem = styled(Form.Item)`
  margin-bottom: 17px;

  .ant-form-item-label {
    padding: 0 0 4px;
  }
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0px;
`;

const AddressPane = styled.div`
  padding: 16px;
  border: 1px solid ${colors.lightGrey};
  border-radius: 2px;
  background-color: ${colors.snow};
  margin: 0px 30px 24px 0px;

  .ymaps-2-1-76-search__suggest-item_selected_yes {
    :hover {
      background: ${colors.aliceBlue};
      font-weight: 600;
    }
  }
`;

const AddressNotes = styled.span`
  font-style: italic;
  font-size: 13px;
`;

const CITY_OPTIONS = [
  "Караганда", "Темиртау", "Нур-Султан", "Алматы", "Рудный", "Костанай",
];

const GeocodingCache = {};

const ClientForm = ({
  t,
  onSubmit,
  initialValues,
  pastAddresses,
  onSelectAddress,
  locations,
  onSelectPickupLocation,
}) => {
  const [form] = Form.useForm();
  const [customerStreet, setCustomerStreet] = useState(initialValues.street);
  const [addressSource, setAddressSource] = useState(initialValues.address_source);
  const [selectedCity, setSelectedCity] = useState(initialValues.city || CITY_OPTIONS[0]);
  const suggestViewRef = useRef(null);

  const getGeocodeAndSelectAddress = (street) => {
    const query = `${selectedCity}, ${street}`;
    Promise.resolve(GeocodingCache[query])
      .then((cachedResult) => {
        if (cachedResult !== undefined) return cachedResult;

        return window.ymaps.geocode(query, { results: 1 })
          .then((res) => (res.geoObjects.get(0) ? {
            city: selectedCity,
            street,
            geo: {
              type: "Point",
              coordinates: res.geoObjects.get(0).geometry.getCoordinates(),
            },
          } : null));
      })
      .then((result) => {
        GeocodingCache[query] = result;
        onSelectAddress(result);
      });
  };

  useEffect(() => {
    if (addressSource !== "new_address") {
      return () => {};
    }

    window.ymaps.ready().then(() => {
      const suggestView = new window.ymaps.SuggestView("client-form_street", {
        provider: {
          suggest(request) {
            return window.ymaps.suggest(`${selectedCity}, ${request}`)
              .then((results) => results.map(({ value }) => {
                // TODO: Works for KZ only so far
                const val = value.split(", ").slice(2).join(", ").trim();
                return { displayName: val, value: val };
              }));
          },
        },
      });
      suggestView.events.add("select", (event) => {
        const street = event.get("item").value;
        form.setFieldsValue({ street });
        setCustomerStreet(street);
      });
      suggestViewRef.current = suggestView;
    });
    return () => suggestViewRef.current?.destroy();
  }, [addressSource, selectedCity]);

  useEffect(() => {
    if (customerStreet) {
      getGeocodeAndSelectAddress(customerStreet);
    }
  }, [selectedCity, customerStreet]);

  const handleAddressSourceChange = (value) => {
    onSelectAddress(null);
    onSelectPickupLocation(null);
    setAddressSource(value);
    form.setFieldsValue({
      address_id: undefined,
      street: undefined,
      flat: undefined,
      notes: undefined,
      location_id: undefined,
    });
  };

  const handleSelectPastAddress = (addressId) => {
    const customerAddress = pastAddresses.find((a) => a.id === addressId);
    onSelectAddress(customerAddress);
  };

  useEffect(() => {
    if (initialValues.address_id) handleSelectPastAddress(initialValues.address_id);
  }, []);

  const renderAddressPane = useMemo(() => {
    const streetValidateStatus = customerStreet && /\d/.test(customerStreet)
      ? "success" : "warning";
    switch (addressSource) {
      case "past_addresses":
        return (
          <FormItem name="address_id">
            <Radio.Group onChange={(e) => handleSelectPastAddress(e.target.value)}>
              {pastAddresses.map((address) => (
                <Radio key={address.id} style={radioStyle} value={address.id}>
                  {address.street}
                  {address.flat && `, ${address.flat}`}
                  {address.notes && <AddressNotes><br />{address.notes}</AddressNotes>}
                </Radio>
              ))}
            </Radio.Group>
          </FormItem>
        );
      case "pickup":
        return (
          <FormItem name="location_id">
            <Radio.Group onChange={(e) => onSelectPickupLocation(e.target.value)}>
              {locations.map((location) => (
                <Radio key={location.id} value={location.id} style={radioStyle}>
                  {location.address
                    ? `${location.name} - ${location.address}`
                    : location.name}
                </Radio>
              ))}
            </Radio.Group>
          </FormItem>
        );
      default:
        return (
          <>
            <Row gutter={16}>
              <Col xs={24} md={12} lg={10}>
                <Form.Item
                  label={t("newOrder.ClientForm.City.Label")}
                  name="city"
                  {...formItemAddress}
                >
                  <Select
                    onChange={setSelectedCity}
                    placeholder={t("newOrder.ClientForm.City.Placeholder")}
                  >
                    {CITY_OPTIONS.map((city) =>
                      <Select.Option key={city} value={city}>{city}</Select.Option>)}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={18}>
                <Form.Item
                  label={t("newOrder.ClientForm.Street.Label")}
                  help={t("newOrder.ClientForm.Street.Help")}
                  hasFeedback={streetValidateStatus === "warning"}
                  validateStatus={streetValidateStatus}
                  name="street"
                  {...formItemAddress}
                >
                  <Input type="search" placeholder={t("newOrder.ClientForm.Street.Placeholder")} />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item
                  label={t("newOrder.ClientForm.Flat.Label")}
                  name="flat"
                  {...formItemAddress}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  label={t("newOrder.ClientForm.Notes.Label")}
                  name="notes"
                  {...formItemAddress}
                >
                  <Input.TextArea
                    rows={2}
                    placeholder={t("newOrder.ClientForm.Notes.Placeholder")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </>
        );
    }
  }, [addressSource, customerStreet]);

  return (
    <Form
      name="client-form"
      form={form}
      layout="vertical"
      initialValues={{ city: CITY_OPTIONS[0], ...initialValues }}
      onFinish={onSubmit}
    >
      <FormItem
        label={t("newOrder.ClientForm.Name.Label")}
        name="name"
        {...formItem}
      >
        <Input />
      </FormItem>

      <FormItem
        label={t("newOrder.ClientForm.PhoneNumber.Label")}
        name="phone_number"
        rules={[
          { required: true },
          { pattern: PHONE_NUMBER_REGEX, message: "Invalid phone number!" },
        ]}
        {...formItem}
      >
        <Input addonBefore={COUNTRY_CODE_NUMBER} />
      </FormItem>

      <StyledFormItem name="address_source">
        <Radio.Group
          onChange={(e) => handleAddressSourceChange(e.target.value)}
          value={addressSource}
        >
          {ADDRESS_SOURCE.map((address_source) => (
            <Radio
              key={address_source.value}
              value={address_source.value}
              disabled={address_source.value === "past_addresses" && pastAddresses.length === 0}
            >
              {address_source.label}
            </Radio>
          ))}
        </Radio.Group>
      </StyledFormItem>

      <AddressPane>
        {renderAddressPane}
      </AddressPane>
    </Form>
  );
};

ClientForm.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  pastAddresses: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  onSelectAddress: PropTypes.func.isRequired,
  onSelectPickupLocation: PropTypes.func.isRequired,
};

export default ClientForm;
