import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Modal, Space, Typography, Button, Badge } from "antd";

import { formatPrice } from "utils/helpers";

import OrderData from "../../../OrderData.container";

const { Text } = Typography;

const Label = styled.label`
  font-size: 18px;
`;

const ModifiersContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .ant-badge-count {
    cursor: pointer;
  }
`;

const ModifiersRadioInputs = ({ item, onClick, selected }) => (
  <Button
    key={item.id}
    size="large"
    style={{ marginRight: 10, marginTop: 10 }}
    type={selected ? "primary" : "default"}
    onClick={onClick}
  >
    {item.name} {item.price ? formatPrice(item.price) : ""}
  </Button>
);

ModifiersRadioInputs.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};

const ModifierSelectInputs = ({
  item, selected, onClick, onRemove, count, displayCount,
}) => (
  <div style={{ marginRight: selected ? 15 : 10, marginTop: 10 }}>
    <Badge count={selected ? "-" : null} onClick={onRemove}>
      <Button
        key={item.id}
        size="large"
        type={selected ? "primary" : "default"}
        onClick={(e) => { e.stopPropagation(); onClick(); }}
      >
        {item.name} {item.price ? formatPrice(item.price) : ""}
        &nbsp;&nbsp;
        <b>{displayCount ? `(x${count})` : ""}</b>
      </Button>
    </Badge>
  </div>
);

ModifierSelectInputs.propTypes = {
  item: PropTypes.object,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  displayCount: PropTypes.bool,
  count: PropTypes.number,
};

const ModifiersModal = ({
  menuItem, onClose, t, onSubmit, orderLine, orderLineIndex,
}) => {
  const { orderTypeInfo } = OrderData.useContainer();
  const [modifiers, setModifiers] = useState({});
  const submitAfterStateChange = useRef(false);
  const modifierGroups = menuItem.menu_item_modifier_groups.map((v) => v.modifier_group);

  const price = menuItem.price + Object.values(modifiers)
    .flat()
    .reduce((acc, item) => acc + item.price, 0);

  const handleSubmit = () => {
    const formattedModifiers = Object.keys(modifiers).map((groupId) => ({
      modifier_group_id: parseInt(groupId, 10),
      list_group_name: modifierGroups.find((group) => group.id === parseInt(groupId, 10)).name,
      value: modifiers[groupId].map((item) => ({
        id: item.id, list_name: item.name, price: item.price,
      })),
    }));

    onSubmit({
      ...menuItem,
      orderLineData: {
        orderLineIndex,
        price,
        modifiers: formattedModifiers,
      },
    });
    onClose();
  };

  const isAllModifierGroupsSingular = modifierGroups
    .every((group) => group.min_selection === 1 && group.max_selection === 1);

  const doneButtonDisabled = modifierGroups.some(({ id, min_selection, max_selection }) => {
    const selectionCount = modifiers[id]?.length ?? 0;
    return selectionCount < min_selection || selectionCount > max_selection;
  });

  useEffect(() => {
    if (!doneButtonDisabled && isAllModifierGroupsSingular && !orderLine) {
      handleSubmit();
    }
  }, [doneButtonDisabled]);

  useEffect(() => {
    if (submitAfterStateChange.current) {
      handleSubmit();
    }
  }, [modifiers]);

  useEffect(() => {
    if (orderLine) {
      setModifiers(orderLine.modifiers.reduce((acc, modifier) => {
        const group = modifierGroups.find((g) => g.id === modifier.modifier_group_id);
        return {
          ...acc,
          [group.id]: modifier.value
            .map(({ id }) => group.modifier_items.find((item) => item.id === id)),
        };
      }, {}));
    } else {
      const defaultModifiersByOrderType = modifierGroups.reduce((acc, group) => ({
        ...acc,
        [group.id]: group.modifier_items
          .filter((i) => i.default_value?.[orderTypeInfo?.type])
          .slice(0, group.max_selection),
      }), {});

      const isReadyToSubmit = modifierGroups.every(({ id, min_selection, max_selection }) => {
        const selectionCount = defaultModifiersByOrderType[id].length;
        return selectionCount >= min_selection && selectionCount <= max_selection
          && selectionCount > 0;
      });

      if (isReadyToSubmit) {
        submitAfterStateChange.current = true;
      }

      setModifiers({
        ...Object.fromEntries(
          // eslint-disable-next-line no-unused-vars
          Object.entries(defaultModifiersByOrderType).filter(([key, value]) => value.length > 0),
        ),
      });
    }
  }, []);

  const handleModifierItem = (group, item) => {
    const selectedItems = modifiers[group.id] || [];
    const isSelected = selectedItems.find((el) => el.id === item.id);

    const isRadioInput = group.min_selection === 1 && group.max_selection === 1;
    if (isRadioInput) {
      if (isSelected) return false;
      return setModifiers({ ...modifiers, [group.id]: [item] });
    }

    const isOneItemPerModifier = group.min_selection === 0 && group.max_selection === 1;
    if (isOneItemPerModifier) {
      return setModifiers({
        ...modifiers,
        [group.id]: isSelected
          ? selectedItems.filter((el) => el.id !== item.id)
          : [item],
      });
    }

    const value = selectedItems.length === group.max_selection
      ? selectedItems.slice(1).concat(item) : selectedItems.concat(item);
    return setModifiers({ ...modifiers, [group.id]: value });
  };

  const handleRemoveModifierItem = (group, item) => {
    const removeIndex = modifiers[group.id].findIndex((i) => i.id === item.id);
    setModifiers({
      ...modifiers,
      [group.id]: modifiers[group.id].filter((i, index) => removeIndex !== index),
    });
  };

  return (
    <Modal
      visible
      title={t("cart.ModifierModal.Title", { name: menuItem.name })}
      onCancel={onClose}
      okText={t("cart.ModifierModal.Add")}
      cancelText={t("cart.ModifierModal.Cancel")}
      zIndex={1040}
      footer={(
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <Text style={{ display: "flex", flex: 1 }} level={5}>
            {t("cart.ModifierModal.Total", { total: formatPrice(price) })}
          </Text>
          <Button onClick={onClose} type="default">{t("cart.ModifierModal.Cancel")}</Button>
          <Button onClick={handleSubmit} type="primary" disabled={doneButtonDisabled}>
            {t(orderLine ? "cart.ModifierModal.Edit" : "cart.ModifierModal.Add")}
          </Button>
        </div>
      )}
    >
      <Space direction="vertical" size="middle">
        {modifierGroups.map((group) => (
          <div key={group.id}>
            <Label
              dangerouslySetInnerHTML={{
                __html: group.max_selection <= 1
                  ? t("cart.ModifierModal.SelectModifier", { name: group.name })
                  : t("cart.ModifierModal.SelectModifiersRange", {
                    from: group.min_selection, to: group.max_selection, name: group.name,
                  }),
              }}
            />
            <div><Text type="secondary">{group.description}</Text></div>
            <ModifiersContainer>
              {group.modifier_items.map((item) => {
                if (group.min_selection === 1 && group.max_selection === 1) {
                  return (
                    <ModifiersRadioInputs
                      key={item.id}
                      item={item}
                      onClick={() => handleModifierItem(group, item)}
                      selected={!!modifiers[group.id]?.find((el) => el.id === item.id)}
                    />
                  );
                }
                const count = modifiers[group.id]?.filter((el) => el.id === item.id).length;
                const displayCount = group.max_selection > 1 && count > 0;
                return (
                  <ModifierSelectInputs
                    key={item.id}
                    item={item}
                    onClick={() => handleModifierItem(group, item)}
                    onRemove={() => handleRemoveModifierItem(group, item)}
                    selected={!!modifiers[group.id]?.find((el) => el.id === item.id)}
                    count={count}
                    displayCount={displayCount}
                  />
                );
              })}
            </ModifiersContainer>
          </div>
        ))}
      </Space>
    </Modal>
  );
};

ModifiersModal.propTypes = {
  menuItem: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  orderLine: PropTypes.object,
  orderLineIndex: PropTypes.number,
};

export default ModifiersModal;
