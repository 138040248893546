import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Popover, Button, Input, Form } from "antd";
import { EditOutlined } from "@ant-design/icons";

const PopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 260px;

  .popover-title {
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 6px;
  }

  .popover-description {
    padding-bottom: 6px;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const NotesBlock = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0 15px;
`;

const StyledEditOutlined = styled(EditOutlined)`
  margin-right: 5px;
`;

const FormItem = styled(Form.Item)`
  margin-bottom: 10px;
`;

const PopoverContent = ({ notes, onSave, placeholder, t }) => (
  <PopoverContainer>
    <Form onFinish={(values) => onSave(values.notes)} initialValues={{ notes: notes || "" }}>
      <FormItem name="notes">
        <Input.TextArea rows={2} placeholder={placeholder} />
      </FormItem>
      <StyledButton type="primary" htmlType="submit">
        {t("Add")}
      </StyledButton>
    </Form>
  </PopoverContainer>
);

PopoverContent.propTypes = {
  notes: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

const NotesPopover = ({
  onSave, notes, placeholder, addNotesText, t, style,
}) => {
  const [show, setShow] = useState(false);
  return (
    <Popover
      visible={show}
      content={() => (
        <PopoverContent
          t={t}
          notes={notes}
          placeholder={placeholder}
          onSave={(updatedNotes) => {
            setShow(false);
            onSave(updatedNotes);
          }}
        />
      )}
      onVisibleChange={setShow}
      trigger="click"
    >
      <NotesBlock style={style}>
        <StyledEditOutlined />
        <i>{notes || addNotesText}</i>
      </NotesBlock>
    </Popover>
  );
};

NotesPopover.propTypes = {
  notes: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  addNotesText: PropTypes.string,
  t: PropTypes.func,
  style: PropTypes.object,
};

export default NotesPopover;
