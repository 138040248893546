import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, Input, Button } from "antd";

import { PHONE_NUMBER_REGEX, COUNTRY_CODE_NUMBER } from "../../../constants/index";

const PhoneNumberForm = ({ t, onSubmit, onNewOrder, isDisabledNewOrder, initialValues }) => {
  const [phoneNumber, setPhoneNumber] = useState("");

  return (
    <Form
      name="phone_number_form"
      layout="inline"
      onFinish={() => onSubmit({ phone_number: phoneNumber })}
      initialValues={initialValues}
    >
      <Form.Item
        name="phone_number"
        rules={[
          { required: true },
          { pattern: PHONE_NUMBER_REGEX, message: "Invalid phone number!" },
        ]}
      >
        <Input
          placeholder={t("checkClient.PhoneNumberForm.EnterPhoneNumber")}
          addonBefore={COUNTRY_CODE_NUMBER}
          onChange={(e) => setPhoneNumber(COUNTRY_CODE_NUMBER.concat(e.target.value))}
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" className="button">
          {t("checkClient.PhoneNumberForm.Continue")}
        </Button>
      </Form.Item>

      <Form.Item>
        <Button type="primary" onClick={onNewOrder} disabled={isDisabledNewOrder}>
          {t("checkClient.PhoneNumberForm.NewOrder")}
        </Button>
      </Form.Item>
    </Form>
  );
};

PhoneNumberForm.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onNewOrder: PropTypes.func.isRequired,
  isDisabledNewOrder: PropTypes.bool.isRequired,
  initialValues: PropTypes.object,
};

export default PhoneNumberForm;
