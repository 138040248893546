import React from "react";
import PropTypes from "prop-types";
import { Button, Space } from "antd";
import { useTranslation } from "react-i18next";

const ActionsBlock = ({ onEdit, onCancel, canEdit, canCancel }) => {
  const { t } = useTranslation();
  return (
    <Space style={{ display: "flex", flexDirection: "column" }} direction="vertical">
      <Button onClick={onEdit} type="primary" ghost disabled={!canEdit}>
        {t("checkClient.Columns.ButtonTitles.EditOrder")}
      </Button>
      <Button onClick={onCancel} danger disabled={!canCancel}>
        {t("checkClient.Columns.ButtonTitles.CancelOrder")}
      </Button>
    </Space>
  );
};

ActionsBlock.propTypes = {
  onEdit: PropTypes.func,
  onCancel: PropTypes.func,
  canEdit: PropTypes.bool,
  canCancel: PropTypes.bool,
};

export default ActionsBlock;
