import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Row, Col, notification } from "antd";

import salemposLogo from "assets/salempos.png";
import callcenterBg from "assets/callcenter_bg.jpg";

import LoginForm from "./components/LoginForm";

const Container = styled.div`
  .image-container {
    height: 100vh;
    background: url(${callcenterBg}) center;
    background-size: cover;
  }
  .form-container {
    background-color: #ebcdcf08;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const Image = styled.img`
  height: 120px;
  background-size: cover;
  margin-bottom: 32px;
`;

const Login = ({ onLogin }) => {
  const handleSubmit = (values) => onLogin(values)
    .catch((err) => notification.error({ message: err.message }));

  return (
    <Container>
      <Row>
        <Col className="image-container" sm={0} xs={0} md={14} lg={16} />
        <Col className="form-container" xs={24} sm={24} md={10} lg={8}>
          <Image src={salemposLogo} alt="logo" />
          <LoginForm onSubmit={handleSubmit} />
        </Col>
      </Row>
    </Container>
  );
};

Login.propTypes = {
  onLogin: PropTypes.func.isRequired,
};

export default Login;
