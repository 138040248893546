import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  Row, Col, Popover, Button, Badge, Typography,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import colors from "../../../theme/colors";
import { formatPrice } from "../../../utils/helpers";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .name-category {
    margin: 4px 10px;
    font-weight: 600;
    color: ${colors.grey};
  }

  .menu-item-group {
    cursor: pointer;
    transition-duration: 0.3s;

    :hover {
      box-shadow: 0 0 3px rgba(0,0,0,0.5);
      transition-duration: 0.3s;
    }
  }

  .ant-badge {
    display: block;
  }

  .ant-badge-count {
    z-index: 2;
  }
`;

const MenuItemGroup = styled(Row)`
  height: 34px;
  padding: 0px 6px;
  border: 0.5px solid ${colors.silver};
  background: ${colors.white};
  display: flex;
  align-items: center;

  .item-name {
    margin-bottom: 0;
    width: 90%;
  }

  .info-icon {
    color: ${colors.lightGrey};
    position: absolute;
    top: 9px;
    right: 7px;
    font-size: 16px;
  }

  .info-icon:hover {
    color: ${colors.blue}
  }
`;

const PopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 260px;

  .popover-title {
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 6px;
  }

  .popover-description {
    padding-bottom: 6px;
  }

  .menu-item {
    display: flex;
    flex-direction: row;
    height: 44px;
    margin: 4px 0px;
    border-radius: 4px;
    align-items: center;
  }

  .menu-item-text {
    display: flex;
    justify-content: flex-start;

    span:first-child {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    span:last-child {
      font-weight: 700;
    }
  }

  .menu-item-badge {
    display: flex;
    justify-content: flex-end;
  }
`;

const PopoverContent = ({ menuItemGroup, orderLines, onAddMenuItemToCard }) => (
  <PopoverContainer>
    <span className="popover-title">{menuItemGroup.name}</span>
    {menuItemGroup.description
      && <span className="popover-description">{menuItemGroup.description}</span>}
    {menuItemGroup.menu_items.map((menuItem) => {
      const count = orderLines.filter((ol) => ol.menu_item.id === menuItem.id)
        .reduce((acc, ol) => acc + ol.count, 0);
      return (
        <Button
          key={menuItem.id}
          className="menu-item"
          onClick={() => onAddMenuItemToCard(menuItem)}
        >
          <Col span={20} className="menu-item-text">
            <span>{menuItem.name}</span>
            &nbsp;
            <span>{formatPrice(menuItem.price)}</span>
          </Col>
          <Col span={4} className="menu-item-badge">
            <Badge count={count} />
          </Col>
        </Button>
      );
    })}
  </PopoverContainer>
);

PopoverContent.propTypes = {
  menuItemGroup: PropTypes.object.isRequired,
  orderLines: PropTypes.array.isRequired,
  onAddMenuItemToCard: PropTypes.func.isRequired,
};

const ItemsPanel = ({ categories, orderLines, onAddMenuItemToCard }) => {
  const getCountMenuItemGroupInCart = (menuItemGroup) =>
    orderLines.filter((orderLine) => orderLine.menu_item.menu_item_group.id === menuItemGroup.id)
      .reduce((acc, current) => acc + current.count, 0);

  return (
    <Container>
      {categories.map((category) => (
        <div key={category.id}>
          <Row className="name-category">
            <span>{category.name}</span>
          </Row>
          <Row>
            {category.menu_item_groups.map((menuItemGroup) => (
              <Col className="menu-item-group" key={menuItemGroup.id} xs={6} xl={4}>
                <Badge count={getCountMenuItemGroupInCart(menuItemGroup)}>
                  {menuItemGroup.menu_items.length > 1 ? (
                    <Popover
                      content={() => (
                        <PopoverContent
                          menuItemGroup={menuItemGroup}
                          orderLines={orderLines}
                          onAddMenuItemToCard={onAddMenuItemToCard}
                        />
                      )}
                      trigger="click"
                    >
                      <MenuItemGroup>
                        <Typography.Paragraph className="item-name" ellipsis={{ rows: 2 }}>
                          {menuItemGroup.name}
                        </Typography.Paragraph>
                        <InfoCircleOutlined className="info-icon" />
                      </MenuItemGroup>
                    </Popover>
                  ) : (
                    <MenuItemGroup onClick={() => onAddMenuItemToCard(menuItemGroup.menu_items[0])}>
                      <Typography.Paragraph className="item-name" ellipsis={{ rows: 2 }}>
                        {menuItemGroup.name}
                      </Typography.Paragraph>
                      <Popover
                        content={() => (
                          <PopoverContent
                            menuItemGroup={menuItemGroup}
                            orderLines={orderLines}
                            onAddMenuItemToCard={onAddMenuItemToCard}
                          />
                        )}
                        trigger="click"
                      >
                        <InfoCircleOutlined
                          className="info-icon"
                          onClick={(e) => e.stopPropagation()}
                        />
                      </Popover>
                    </MenuItemGroup>
                  )}
                </Badge>
              </Col>
            ))}
          </Row>
        </div>
      ))}
    </Container>
  );
};

ItemsPanel.propTypes = {
  categories: PropTypes.array.isRequired,
  orderLines: PropTypes.array.isRequired,
  onAddMenuItemToCard: PropTypes.func.isRequired,
};

export default ItemsPanel;
