import gql from "graphql-tag";

export const CUSTOMER_QUERY = gql`
  query GetCustomer($phone_number: String!) @hasura {
    customers(where: { phone_number: { _eq: $phone_number } }) {
      id
      name
      phone_number
      customer_addresses {
        id
        street
        flat
        notes
        geo
      }
    }
  }
`;

const CustomerOrdersFragment = gql`
  fragment CustomerOrdersFragment on orders {
    id
    time
    type
    number
    source
    notes
    list_user
    status
    status_updated_at
    list_price
    created_at
    location {
      id
      name
      phone_number
    }
    delivery_info {
      id
      address {
        id
        street
        house
        flat
        notes
      }
    }
    courier {
      id
      name
      phone_number
    }
    order_payments {
      type
      amount
    }
  }
`;

export const CUSTOMER_COMPLETED_ORDERS_QUERY = gql`
  ${CustomerOrdersFragment}
  query CustomerCompletedOrders($customer_id: Int!) @hasura {
    orders(
      where: {
        customer_id: { _eq: $customer_id },
        status: { _in: ["completed", "cancelled"] }
      }
      limit: 20
      order_by: { id: desc }
    ) {
      ...CustomerOrdersFragment
    }
    orders_aggregate(
      where: {
        customer_id: { _eq: $customer_id },
        status: { _in: ["completed", "cancelled"] }
      }
    ) {
      aggregate {
        count(columns: id)
      }
    }
  }
`;

export const CUSTOMER_ORDERS_QUERY = gql`
  ${CustomerOrdersFragment}
  query CustomerOrders($customer_id: Int!) @hasura {
    orders(
      where: {
        customer_id: { _eq: $customer_id },
        status: { _nin: ["completed", "cancelled"] }
      }
    ) {
      ...CustomerOrdersFragment
      order_lines {
        id
        display_name
        count
        price
        total_price
        status
        status_updated_at
        notes
        modifiers
      }
    }
  }
`;
