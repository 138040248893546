import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Row, Col } from "antd";

import { formatPrice } from "../../../utils/helpers";
import colors from "../../../theme/colors";

const StyledRow = styled(Row)`
  padding-left: 30px;
`;

const Title = styled.span`
  && {
    font-size: 24px;
    font-weight: 500;
  }
`;

const ErrorText = styled.span`
  font-size: 16px;
  color: ${colors.red};
`;

const FlexCol = styled(Col)`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  > span {
    margin: 7px 0px;
    font-size: 16px;
    
    > span {
      color: ${colors.black};
      font-weight: 500;
    }
  }
`;

const LocationInfo = ({ address, deliveryZone, t }) => (
  <StyledRow>
    <Col span={24}>
      <Title>{t("newOrder.LocationInfo.Title")}</Title>
    </Col>
    <FlexCol span={24}>
      {address && (
        <span>
          {t("newOrder.LocationInfo.DeliveryAddress")}:&nbsp;
          <span>{address.street}</span>
        </span>
      )}
      {deliveryZone ? (
        <>
          <span>
            {t("newOrder.LocationInfo.Name")}:&nbsp;
            <span>{deliveryZone.name}</span>
          </span>
          <span>
            {t("newOrder.LocationInfo.DeliveryAmount")}:&nbsp;
            <span>{formatPrice(deliveryZone.price)}</span>
          </span>
          <span>
            {t("newOrder.LocationInfo.MinOrderSumForDelivery")}:&nbsp;
            <span>{formatPrice(deliveryZone.min_order_sum_for_delivery)}</span>
          </span>
          {deliveryZone.order_sum_for_free_delivery && (
            <span>
              {t("newOrder.LocationInfo.OrderSumForFreeDelivery")}:&nbsp;
              <span>{formatPrice(deliveryZone.order_sum_for_free_delivery)}</span>
            </span>
          )}
          <span>
            {t("newOrder.LocationInfo.Location")}:&nbsp;
            <span>{deliveryZone.location.name}</span>
          </span>
          <span>
            {t("newOrder.LocationInfo.Address")}:&nbsp;
            <span>{deliveryZone.location.address || "-"}</span>
          </span>
          <span>
            {t("newOrder.LocationInfo.LocationPhoneNumber")}:&nbsp;
            <span>{deliveryZone.location.phone_number || "-"}</span>
          </span>
          <span>
            {t("newOrder.LocationInfo.Description")}:&nbsp;
            <span>{deliveryZone.description || "-"}</span>
          </span>
          <span>
            {t("newOrder.LocationInfo.LocationDescription")}:&nbsp;
            <span>{deliveryZone.location.description || "-"}</span>
          </span>
        </>
      ) : (
        <ErrorText>{t("newOrder.LocationInfo.NotInDeliveryZoneMessage")}</ErrorText>
      )}
    </FlexCol>
  </StyledRow>
);

LocationInfo.propTypes = {
  t: PropTypes.func.isRequired,
  deliveryZone: PropTypes.shape({
    name: PropTypes.string,
    price: PropTypes.number,
    description: PropTypes.string,
    order_sum_for_free_delivery: PropTypes.number,
    min_order_sum_for_delivery: PropTypes.number,
    location: PropTypes.shape({
      name: PropTypes.string,
      address: PropTypes.string,
      phone_number: PropTypes.string,
      description: PropTypes.string,
    }),
  }),
  address: PropTypes.shape({
    street: PropTypes.string,
  }),
};

export default LocationInfo;
