import React from "react";
import {
  BellFilled,
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  LikeOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";

import i18n from "../i18n";

export const PHONE_NUMBER_REGEX = /^\(*\+*[1-9]{0,3}\)*-*[1-9]{0,3}[-. /]*\(*[2-9]\d{2}\)*[-. /]*\d{3}[-. /]*\d{2}[-. /]*\d{2} *e*x*t*\.* *\d{0,4}$/;

export const COUNTRY_CODE_NUMBER = "+7";

export const ORDER_LINE_STATUS_LABELS = i18n.t("const:order_line_status", { returnObjects: true });

export const ORDER_STATUS_LABELS = i18n.t("const:order_status", { returnObjects: true });

export const ORDER_LINE_STATUS_ICONS = {
  created: <BellFilled />,
  received: <ClockCircleOutlined />,
  processing: <ThunderboltOutlined />,
  ready: <CheckOutlined />,
  served: <LikeOutlined />,
  cancelled: <CloseOutlined />,
};

export const ORDER_LINE_STATUS_COLORS = {
  received: "#e7707d",
  processing: "#28cacc",
  ready: "#83d160",
  served: "#ffce45",
  created: "#bb1f1f",
  cancelled: "#fb3e44",
};
