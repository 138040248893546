import gql from "graphql-tag";

export const DELIVERY_ZONES_AND_LOCATIONS_QUERY = gql`
  query @hasura {
    delivery_zones {
      id
      name
      geo
      price
      description
      min_order_sum_for_delivery
      order_sum_for_free_delivery
      location {
        id
        name
        phone_number
        address
        description
      }
    }
    locations {
      id
      name
      address
    }
  }
`;
