import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { Row, Col, Button, Modal, notification } from "antd";
import { DeleteOutlined, ExclamationCircleOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import CancelOrderModal from "pages/checkClient/components/CancelOrderModal";
import { ORDER_STATUS_LABELS } from "constants/index";
import { getDisplayNumber } from "pages/checkClient/constants";

import colors from "../theme/colors";
import OrderData from "../OrderData.container";

const Container = styled(Row)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  background: ${colors.solitude};
  border-top: 1px solid ${colors.silver};

  .footer-button-container {
    display: flex;
    height: 100%;
    border-right: 1px solid ${colors.silver};
  }

  .footer-button {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
  }

  .location-link {
    height: 100%;
    width: 50%;
    padding: 0px 16px;
    display: flex;
    align-items: center;
    border-right: 1px solid ${colors.silver};
  }

  .danger {
    color: ${colors.red};
  }

  .submit-button-container {
    height: 100%;
    border-left: 1px solid ${colors.silver};
  }

  .disabled-link {
    pointer-events: none;
  }
`;

const Footer = ({ history, children }) => {
  const { t } = useTranslation();
  const { orderTypeInfo, reset, cancelOrder, targetOrder } = OrderData.useContainer();
  const [cancelModalVisible, setCancelModalVisible] = useState(false);

  const handleClearOrder = () => {
    reset();
    history.push("/check-client");
  };

  const handleCancelOrder = (reason) => cancelOrder(reason)
    .then(() => handleClearOrder())
    .catch(notification.error);

  const handleShowCancelOrder = () => {
    if (targetOrder) {
      setCancelModalVisible(true);
    } else {
      Modal.confirm({
        title: t("CancelOrderModal.CancelMessage"),
        icon: <ExclamationCircleOutlined />,
        onOk() {
          handleClearOrder();
        },
        okText: t("CancelOrderModal.Yes"),
        okType: "danger",
        cancelText: t("CancelOrderModal.Cancel"),
      });
    }
  };

  return (
    <Container>
      <div className="footer-button-container">
        {targetOrder && (
          <div style={{ borderRight: `1px solid ${colors.silver}` }}>
            <Button
              className="footer-button"
              onClick={handleClearOrder}
              type="link"
            >
              <ArrowLeftOutlined />
            </Button>
          </div>
        )}
        <Button
          className="footer-button"
          onClick={handleShowCancelOrder}
          type="link"
          danger
        >
          <DeleteOutlined />
        </Button>
      </div>
      <div style={{ flex: 1, cursor: targetOrder ? "not-allowed" : "default" }}>
        {orderTypeInfo ? (
          <Link to="new-order" className={`location-link ${targetOrder ? "disabled-link" : ""}`}>
            <span>{orderTypeInfo.locationInfo}</span>
          </Link>
        ) : (
          <Link to="new-order" className={`location-link danger ${targetOrder ? "disabled-link" : ""}`}>
            <span>{t("footer.NoAddressSelected")}</span>
          </Link>
        )}
      </div>
      <Col span={6} className="submit-button-container">
        {children}
      </Col>
      <CancelOrderModal
        t={t}
        hint={targetOrder && t("checkClient.CancelModal.OrderOnDeliveryHint",
          { status: ORDER_STATUS_LABELS[targetOrder.status] })}
        title={targetOrder && t("checkClient.CancelModal.Title",
          { order_number: getDisplayNumber(null, targetOrder) })}
        visible={cancelModalVisible}
        onClose={() => setCancelModalVisible(false)}
        onSubmit={handleCancelOrder}
      />
    </Container>
  );
};

Footer.propTypes = {
  history: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
};

export default withRouter(Footer);
