/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Layout, Avatar, Dropdown, Menu } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import colors from "../theme/colors";

const LayoutHeader = styled(Layout.Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LinkTitle = styled(Link)`
  color: ${colors.white};
  font-size: 26px;
  max-height: 64px;
`;

const DropdownContent = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-right: 4px;
  padding-left: 4px;
  :hover {
    background: ${colors.blue};
  }
`;

const Text = styled.span`
  color: ${(props) => props.color || colors.black};
  margin-left: 4px;
  margin-right: 4px;
`;

const Header = ({ user, onLogout }) => {
  const { t } = useTranslation();
  const menu = (
    <Menu>
      <Menu.Item>
        <a onClick={onLogout}>
          <LogoutOutlined />
          <Text>{t("header.Logout")}</Text>
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <LayoutHeader>
      <LinkTitle to="/">
        <span>{t("header.Title")}</span>
      </LinkTitle>
      <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
        <DropdownContent>
          <Avatar src="https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png" />
          <Text color={colors.white}>{user.name}</Text>
        </DropdownContent>
      </Dropdown>
    </LayoutHeader>
  );
};

Header.propTypes = {
  onLogout: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default Header;
