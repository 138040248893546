import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { ApolloProvider } from "@apollo/client";
import moment from "moment-timezone";
import preval from "preval.macro";

import client from "./client";
import App from "./App";
import "./i18n";

import "./index.less";

const locale = window.navigator.userLanguage || window.navigator.language;
moment.locale(locale);

const buildTime = preval`module.exports = new Date().toLocaleString([], {timeZoneName: "short"});`;
console.log(`Build time: ${buildTime}`);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401
      && !window.location.href.includes("/login")) {
      window.location.assign("/login");
    }
    return Promise.reject(error);
  },
);

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById("root"),
);
