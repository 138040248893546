export default {
  lightGrey: "#d9d9d9",
  grey: "#ada5a5",
  darkGray: "#d4d4d4",
  solitude: "#f0f2f5",
  snow: "#fafafa",
  silver: "#c5c5c5",
  whisper: "#eeeeee",
  black: "#000000",
  white: "#ffffff",
  blue: "#1890ff",
  red: "#f5222d",
  green: "#52c41a",
  darkGreen: "#274e13",
  gold: "#faad14",
  purple: "#722ed1",
  magenta: "#eb2f96",
  geekblue: "#2f54eb",
  lime: "#a0d911",
  cyan: "#13c2c2",
  yellow: "#ffff00",
  rawUmber: "#7f6000",
  aliceBlue: "#e6f7ff",
};
