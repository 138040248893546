import React from "react";
import moment from "moment";
import { Tag } from "antd";

import ActionsBlock from "./components/ActionsBlock";

import colors from "../../theme/colors";
import { formatPrice, formatRelativeTime } from "../../utils/helpers";
import i18n from "../../i18n";

export const STATUSES = {
  received: { label: i18n.t("checkClient.Status.Received"), color: colors.blue },
  processing: { label: i18n.t("checkClient.Status.Processing"), color: colors.gold },
  ready: { label: i18n.t("checkClient.Status.Ready"), color: colors.cyan },
  on_delivery: { label: i18n.t("checkClient.Status.OnDelivery"), color: colors.purple },
  served: { label: i18n.t("checkClient.Status.Served"), color: colors.lime },
  completed: { label: i18n.t("checkClient.Status.Completed"), color: colors.green },
  cancelled: { label: i18n.t("checkClient.Status.Canceled"), color: colors.red },
  created: { label: i18n.t("checkClient.Status.Created"), color: colors.solitude },
};

const CAN_EDIT_STATUSES = ["created", "received", "processing", "ready"];
const CAN_CANCEL_STATUSES = ["created", "received", "processing", "ready", "on_delivery"];

const getDeliveryAddress = (order) => {
  const { street, house, flat, notes } = order.delivery_info.address;
  return [street, house, flat, notes].filter((el) => el).join(", ");
};

export const getDisplayNumber = (_, { number, source }) => {
  switch (source) {
    case "callcenter": return `${number}C`;
    case "api": return `${number}A`;
    default: return number.toString();
  }
};

const getCourierName = (courier) => {
  if (courier) {
    return <span>{courier.name} <i>{courier.phone_number}</i></span>;
  }
  return <i>{i18n.t("checkClient.Columns.DetailLabels.CourierNotAssigned")}</i>;
};

const renderOrderDetails = (type, order) => (
  <span>
    {i18n.t(`const:order_type.${type}`)}
    <br />
    {i18n.t("checkClient.Columns.DetailLabels.Location")}:
    &nbsp;{order.location.name} <i>{order.location.phone_number}</i>
    {order.notes && (
      <div>
        {i18n.t("checkClient.Columns.DetailLabels.Notes")}:&nbsp;<i>{order.notes}</i>
      </div>
    )}
    {order.delivery_info && (
      <div>
        {i18n.t("checkClient.Columns.DetailLabels.Address")}: {getDeliveryAddress(order)}
        <br />
        {i18n.t("checkClient.Columns.DetailLabels.Courier")}:
        &nbsp;{getCourierName(order.courier)}
      </div>
    )}
  </span>
);

export const getOrderColumns = (onEditOrder, onCancelOrder) => [
  {
    dataIndex: "id",
    render: getDisplayNumber,
  },
  {
    title: i18n.t("checkClient.Columns.OrderDate"),
    dataIndex: "time",
    sorter: (lhs, rhs) => lhs.id - rhs.id,
    defaultSortOrder: "descend",
    render: (date, order) => {
      if (order.status !== "completed") {
        return formatRelativeTime(date);
      }
      return moment(date).format("lll");
    },
  },
  {
    title: i18n.t("checkClient.Columns.Details"),
    dataIndex: "type",
    render: renderOrderDetails,
  },
  {
    title: i18n.t("checkClient.Columns.User"),
    dataIndex: "list_user",
  },
  {
    title: i18n.t("checkClient.Columns.Amount"),
    dataIndex: "list_price",
    render: formatPrice,
  },
  {
    title: i18n.t("checkClient.Columns.Payment"),
    dataIndex: "order_payments",
    render: (order_payments) => formatPrice(
      order_payments.reduce(
        (acc, curr) => (curr.type === "payment" ? acc + curr.amount : acc - curr.amount), 0,
      ),
    ),
  },
  {
    title: i18n.t("checkClient.Columns.Status"),
    dataIndex: "status",
    render: (status, order) => {
      const currentStatus = STATUSES[status];
      if (order.status !== "completed") {
        return (
          <span>
            <Tag color={currentStatus.color}>{currentStatus.label}</Tag>
            <br />
            {formatRelativeTime(order.status_updated_at)}
          </span>
        );
      }
      return <Tag color={currentStatus.color}>{currentStatus.label}</Tag>;
    },
  },
  (onEditOrder || onCancelOrder) && {
    render: (_, order) => (
      <ActionsBlock
        canEdit={CAN_EDIT_STATUSES.includes(order.status)}
        canCancel={CAN_CANCEL_STATUSES.includes(order.status)}
        onEdit={(() => onEditOrder(order))}
        onCancel={() => onCancelOrder(order)}
      />
    ),
  },
].filter((el) => el);
