import moment from "moment";
import _ from "lodash";

export const formatPrice = (number = 0) => `${new Intl.NumberFormat({}, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}).format(number)} тг.`;

export const formatRelativeTime = (time) => {
  const seconds = moment().diff(time, "seconds");
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  return hours === 0 ? `${minutes} мин назад` : `${hours} ч ${minutes} мин назад`;
};

export const getMenuItemName = (mi) => {
  if (mi.menu_item_group.name === mi.name) {
    return mi.name;
  }
  return `${mi.menu_item_group.name} ${mi.name}`;
};

export const isModifiersEqual = (aModifiers, bModifiers) => {
  if (!aModifiers || !bModifiers) return true;
  if (aModifiers.length !== bModifiers.length) return false;
  return aModifiers.every((aModifier) => {
    const bModifier = bModifiers.find((m) => m.modifier_group_id === aModifier.modifier_group_id);
    if (!bModifier) return false;
    return aModifier.value.map((i) => i.id).sort((a, b) => a - b).join()
      === bModifier.value.map((i) => i.id).sort((a, b) => a - b).join();
  });
};

export const stringifyModifiers = (items) => {
  const grouped = _.groupBy(items.map((i) => i.list_name));
  return Object.keys(grouped)
    .map((name) => {
      const count = grouped[name].length;
      return count > 1 ? `${name}\u00A0(x${count})` : name;
    })
    .join(", ");
};
