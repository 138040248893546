import gql from "graphql-tag";

export const CURRENT_USER = gql`
  query CurrentUser @hasura {
    current_user_view {
      id
      name
      email
      phone_number
    }
  }
`;
