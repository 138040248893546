import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Form, Radio, InputNumber } from "antd";

import OrderData from "../../../OrderData.container";
import i18n from "../../../i18n";

const StyledForm = styled(Form)`
  padding: 8px 16px;

  .radio-container {
    height: 32px;
    display: flex;
    align-items: center;
  }

  .form-item-margin-bottom {
    margin-bottom: 0px;
  }
`;

const formItemLayout = {
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 17,
  },
  labelAlign: "left",
  style: {
    marginBottom: 10,
  },
};

export const PAYMENT_METHOD_TYPE = {
  CASH: "cash",
  CUSTOM: "custom",
};

const PAYMENT_METHOD_NAME = {
  salempos_generated_card: i18n.t("const:payment_method_name.card"),
  salempos_generated_cash: i18n.t("const:payment_method_name.cash"),
};

const BasketForm = ({ t, onSubmit, paymentMethods, showPaymentMethodInput }) => {
  const {
    paymentMethodId,
    setPaymentMethodId,
    discount,
    setDiscount,
    cash,
    setCash,
  } = OrderData.useContainer();
  const initialPaymentMethodId = paymentMethodId || paymentMethods[0]?.id;

  const [form] = Form.useForm();

  useEffect(() => setPaymentMethodId(initialPaymentMethodId), []);
  useEffect(() => form.setFieldsValue({ discount: discount * 100 }), [discount]);

  const foundCash = paymentMethods.find((pm) => pm.type === PAYMENT_METHOD_TYPE.CASH);

  const handleDiscount = (value) => {
    const newDiscount = (value / 100) || 0;
    setDiscount(Math.max(0, Math.min(1, newDiscount)));
  };

  return (
    <StyledForm
      form={form}
      name="basket-form"
      onFinish={onSubmit}
      initialValues={{
        discount: discount * 100,
        payment_method_id: initialPaymentMethodId,
        cash,
      }}
    >
      {showPaymentMethodInput && (
        <Form.Item
          name="payment_method_id"
          label={t("cart.BasketForm.PaymentMethod.Label")}
          {...formItemLayout}
        >
          <Radio.Group onChange={(e) => setPaymentMethodId(e.target.value)}>
            {paymentMethods.map((method) => (
              <div key={method.id} className="radio-container">
                <Radio value={method.id}>
                  {method.type === PAYMENT_METHOD_TYPE.CUSTOM
                    ? method.name : PAYMENT_METHOD_NAME[method.name]}
                </Radio>
                {method.type === PAYMENT_METHOD_TYPE.CASH && foundCash.id === paymentMethodId && (
                  <Form.Item name="cash" className="form-item-margin-bottom">
                    <InputNumber
                      onChange={(value) => setCash(value)}
                      type="number"
                      placeholder={t("cart.BasketForm.CashReturn.Placeholder")}
                    />
                  </Form.Item>
                )}
              </div>
            ))}
          </Radio.Group>
        </Form.Item>
      )}

      <Form.Item
        name="discount"
        label={t("cart.BasketForm.Discount.Label")}
        rules={[{ required: true, type: "number" }]}
        {...formItemLayout}
      >
        <InputNumber
          min={0}
          max={100}
          formatter={(value) => `${value}%`}
          parser={(value) => value.replace("%", "")}
          onChange={handleDiscount}
        />
      </Form.Item>
    </StyledForm>
  );
};

BasketForm.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  showPaymentMethodInput: PropTypes.bool,
  paymentMethods: PropTypes.array.isRequired,
};

export default BasketForm;
