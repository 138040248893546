/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import styled from "styled-components";
import { Popconfirm } from "antd";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";

import colors from "../../theme/colors";
import { formatPrice, getMenuItemName } from "../../utils/helpers";

const Counter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    padding: 0px 6px;
  }

  .icon-button {
    padding: 0px;
    font-size: 20px;
    transition: color 0.3s;

    :hover {
      color: ${colors.blue};
      transition: color 0.3s;
    }
  }
`;

export const getProductColumns = (
  t,
  onDecrementOrDeleteOrderLine,
  onIncrementOrderLine,
  onDeleteOrderLine,
) => (
  [
    {
      title: t("cart.Columns.Name"),
      dataIndex: ["menu_item", "name"],
      colSpan: 2,
      render: (name, orderLine, idx) => ({
        children: orderLine.id ? (
          getMenuItemName(orderLine.menu_item)
        ) : (
          <Popconfirm
            title={t("cart.Columns.DeleteOrderLinePopconfirm.Title")}
            okText={t("cart.Columns.DeleteOrderLinePopconfirm.Yes")}
            cancelText={t("cart.Columns.DeleteOrderLinePopconfirm.No")}
            onConfirm={() => onDeleteOrderLine(idx)}
          >
            <a onClick={(e) => e.preventDefault()}>
              {getMenuItemName(orderLine.menu_item)}
            </a>
          </Popconfirm>
        ),
        props: {
          colSpan: 2,
        },
      }),
    },
    {
      title: t("cart.Columns.Count"),
      dataIndex: "count",
      align: "center",
      width: "25%",
      render: (count, orderLine, idx) => (
        <Counter>
          {orderLine.id ? <span>{count}</span> : (
            <>
              <MinusCircleOutlined
                className="icon-button"
                onClick={() => onDecrementOrDeleteOrderLine(idx)}
              />
              <span>{count}</span>
              <PlusCircleOutlined
                className="icon-button"
                onClick={() => onIncrementOrderLine(idx)}
              />
            </>
          )}
        </Counter>
      ),
    },
    {
      title: t("cart.Columns.Total"),
      dataIndex: "price",
      width: "33%",
      render: (price, ol) => formatPrice(price * ol.count),
    },
  ]
);
