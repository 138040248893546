import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, Input, Modal, Typography } from "antd";

const CancelOrderModal = ({
  t, hint, title, visible, onClose, onSubmit,
}) => {
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleSubmit = () => {
    form.validateFields()
      .then(({ reason }) => {
        setConfirmLoading(true);
        onSubmit(reason)
          .finally(() => {
            setConfirmLoading(false);
            onClose();
            form.resetFields();
          });
      });
  };

  return (
    <Modal
      visible={visible}
      title={title}
      okText={t("checkClient.CancelModal.CancelOrder")}
      cancelText={t("checkClient.CancelModal.Close")}
      okButtonProps={{ danger: true }}
      onCancel={onClose}
      onOk={handleSubmit}
      confirmLoading={confirmLoading}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="reason"
          label={t("checkClient.CancelModal.CancelReason")}
          rules={[{ required: true, message: t("checkClient.CancelModal.Required") }]}
        >
          <Input placeholder={t("checkClient.CancelModal.CancelReasonPlaceholder")} />
        </Form.Item>
        {hint && <Typography><i>{hint}</i></Typography>}
      </Form>
    </Modal>
  );
};

CancelOrderModal.propTypes = {
  t: PropTypes.func,
  hint: PropTypes.string,
  title: PropTypes.string,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
};

export default CancelOrderModal;
