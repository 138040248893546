import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import axios from "axios";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { Layout, Spin } from "antd";
import { useQuery } from "@apollo/client";

import { CURRENT_USER } from "./commonQueries";
import OrderData from "./OrderData.container";

import Header from "./components/Header";
import { Login } from "./pages/auth";
import { CheckClient } from "./pages/checkClient";
import { NewOrder } from "./pages/newOrder";
import { Cart } from "./pages/cart";

const Loader = styled(Spin)`
  position: absolute;
  top: 50%;
  left: 50%;
`;

const PrivateRoute = ({ path, children }) => {
  const { customer } = OrderData.useContainer();
  return (
    <Route
      path={path}
      render={() => (customer ? children : <Redirect to="/check-client" />)}
    />
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.element.isRequired,
  path: PropTypes.string.isRequired,
};

const App = () => {
  const { client, data, loading, error, refetch } = useQuery(CURRENT_USER, {
    fetchPolicy: "cache-and-network",
  });

  const handleLogin = (values) => axios.post("/auth/callcenter/login", values)
    .then(() => refetch());

  const handleLogout = () => axios.delete("/auth/callcenter/logout")
    .then(() => client.resetStore());

  if (loading) return <Loader />;

  return data && !error ? (
    <BrowserRouter>
      <OrderData.Provider>
        <Layout className="app-layout">
          <Header onLogout={handleLogout} user={data.current_user_view[0]} />
          <Layout.Content>
            <Switch>
              <Route path="/check-client/:pathPhoneNumber?" component={CheckClient} />
              <PrivateRoute path="/new-order">
                <NewOrder />
              </PrivateRoute>
              <PrivateRoute path="/cart/:id">
                <Cart />
              </PrivateRoute>
              <PrivateRoute path="/cart">
                <Cart />
              </PrivateRoute>

              <Redirect to="/check-client" />
            </Switch>
          </Layout.Content>
        </Layout>
      </OrderData.Provider>
    </BrowserRouter>
  ) : (
    <Login onLogin={handleLogin} />
  );
};

export default App;
